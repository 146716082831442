import React from "react";
import Layout from "../layout";
import Seo from "../components/Seo/Seo";
import Contact from "../components/Contact/Contact";
import config from "../../data/SiteConfig";
import { graphql } from "gatsby";
import GImage from "../components/Picture/GImage";
import nl2br from "react-nl2br";

const LiensPage = ({ data: gqlData }) => {
  const { directus } = gqlData;
  return (
    <Layout>
      <Seo
        title={`Liens`}
        description={config.siteDescription3}
        article={false}
      />
      <div className="h-20 md:h-28"></div>
      {directus.partenaires && directus.partenaires.illustration !== null && (
        <GImage
          className="h-44 md:h-72 bg-liens bg-cover"
          src={directus.partenaires.illustration.imageFile}
          alt="Image de la page des liens des partenaires du secteur langues du Groupe Français d'éducation Nouvelle"
        />
      )}
      <div className="liens-container pt-8">
        {directus.partenaires && (
          <h1 className="text-center pt-6 mb-8 px-3 sm:px-6">
            {directus.partenaires.title}
          </h1>
        )}
        <hr className="bg-red-500 border-none h-2" />
        <div className="max-w-6xl mx-auto pt-0 pb-2 px-3 sm:px-6">
          {directus.partenaires && directus.partenaires.intro && (
            <p className="mt-6 mb-10 text-center">
              {nl2br(directus.partenaires.intro)}
            </p>
          )}
        </div>
        <div className="max-w-6xl mx-auto pt-3 pb-10 px-3 sm:px-6">
          {directus.partenaires && directus.partenaires.description && (
            <div
              className="mt-6 mb-10 text-editor"
              dangerouslySetInnerHTML={{
                __html: `${directus.partenaires.description}`,
              }}
            />
          )}
        </div>
        {directus.contact && (
          <Contact contact={directus.contact} background={true} />
        )}
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    directus: allDirectus {
      partenaires {
        title
        intro
        description
        illustration {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
      contact {
        title
        subtitle
        intro
        informations
        illustration {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
    }
  }
`;

export default LiensPage;
